@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.table > tbody > tr > td {
	vertical-align: middle;
}

.panel-heading {
	height: 50px;
	padding: 0 15px;
	position: relative;

	& .panel-title {
		line-height: 50px;
	}

	& .panel-actions {		
    	position: absolute;
    	top: 8px;
    	right: 15px;
	}
}
	